import Header from "../../../Components/Header/Header";

function Emily() {
  return (
    <>
      <Header />
      <>Emily</>
    </>
  );
}

export default Emily;
