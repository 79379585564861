import Header from "../../Components/Header/Header";
import Christmas23 from "./Christmas23";

const Updates = () => {
  return (
    <>
      <Header />
      <Christmas23 />
    </>
  );
};

export default Updates;
