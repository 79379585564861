import Header from "../../../Components/Header/Header";

function Sam() {
  return (
    <>
      <Header />
      <>Sam</>
      <></>
    </>
  );
}

export default Sam;
